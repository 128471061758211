<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>租赁订单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch" @reset="onReset">
        <template>
          <a-form-item label="商品名称" class="common-search_form-item">
            <a-tree-select
              v-model="skuId"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="productList"
              :replace-fields="productTreeFields"
              tree-node-filter-prop="title"
              :allow-clear="true"
              :show-checked-strategy="SHOW_PARENT"
            ></a-tree-select>
          </a-form-item>
        </template>
      </common-search>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="timeScope" slot-scope="text, record">
            <div>
              起：{{
                record.startTime ? tsFormat(record.startTime) : "暂无信息"
              }}
            </div>
            <div>
              止：{{ record.endTime ? tsFormat(record.endTime) : "暂无信息" }}
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record.orderNo)">
              查看
            </a>
            <a @click="onLoadActivityDetail(record.activityId)">
              所属活动
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="openModal"
      title="租赁活动"
      @cancel="openModal = false"
      class="modal"
    >
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动标题">
              {{
                activityDetail.activityTitle
                  ? activityDetail.activityTitle
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="渠道类型">
              {{
                activityDetail.distributorTypeValue
                  ? activityDetail.distributorTypeValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              {{
                activityDetail.corporationName
                  ? activityDetail.corporationName
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动时间">
              {{
                activityDetail.startTime
                  ? dtFormat(activityDetail.startTime)
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动状态">
              {{
                activityDetail.activityStatusValue
                  ? activityDetail.activityStatusValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="举办地点">
              {{
                activityDetail.hostAreaName +
                  (activityDetail.hostDetailAddress
                    ? activityDetail.hostDetailAddress
                    : "")
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <div class="label">
            租赁车辆（{{
              activityDetail.goodsVoList
                ? activityDetail.goodsVoList.length
                : 0
            }}辆）:
          </div>
          <div class="label__content">
            <div
              class="goods"
              v-if="
                !isOpenRide &&
                  activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
            >
              <div
                v-for="(item, index) in lessGoodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="goods"
              v-if="
                isOpenRide ||
                  (activityDetail.goodsVoList &&
                    activityDetail.goodsVoList.length <= 2)
              "
            >
              <div
                v-for="(item, index) in activityDetail.goodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
              class="openPhoto"
              @click="isOpenRide = !isOpenRide"
            >
              <span>{{ !isOpenRide ? "展开" : "收起" }}</span>
              <span v-if="!isOpenRide">
                <a-icon type="down" />
              </span>
              <span v-else>
                <a-icon type="up" />
              </span>
            </div>
          </div>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import {
  fetchCorporationList,
  fetchExamineRideDetail,
  fetchTrialLeaseList
} from "@/services/ActivityService";
import { fetchProductsAndSkuTree } from "@/services/ValueBag";
import { TreeSelect } from "ant-design-vue";

const columns = [
  {
    title: "用户名称",
    dataIndex: "consumerName"
  },
  {
    title: "手机号",
    dataIndex: "mobile"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "举办地区",
    dataIndex: "hostAreaName"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatus"
  },
  {
    title: "租赁起止时间",
    dataIndex: "timeScope",
    key: "timeScope",
    scopedSlots: { customRender: "timeScope" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const productTreeFields = {
  children: "skuList",
  title: "name",
  key: "id",
  value: "id"
};
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  data() {
    return {
      fields: [
        {
          label: "用户名称",
          key: "consumerName",
          component: "j-input"
        },
        {
          label: "序列号",
          key: "goodsSn",
          component: "j-input"
        },
        {
          label: "机构名称",
          key: "distributorId",
          component: "j-select-search",
          options: [],
          getOptions: () => {
            return this.onSearchSellerCorporation();
          }
        },
        {
          label: "套餐时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "订单状态",
          key: "orderStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "已完成",
              value: "true"
            },
            {
              label: "试骑中",
              value: "false"
            }
          ]
        },
        {
          label: "举办地区",
          key: "consigneeProvinceCode,consigneeCityCode,consigneeCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      tsFormat,
      dtFormat,
      openModal: false,
      SHOW_PARENT,
      productTreeFields,
      skuId: "",
      form: {},
      editForm: this.$form.createForm(this),
      tblData: [],
      productList: [],
      lessGoodsVoList: [],
      imageBaseUrl,
      organizationList: [],
      activityDetail: {},
      isOpen: false,
      isOpenRide: false,
      bikeStations: "",
      columns,
      visible: false,
      areaTreeData: [], // 行政区数据
      selectedRowKeys: [],
      buildHeaders,
      baseURL,
      headers: buildHeaders(),
      hasAllDelete: false,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false
    };
  },
  created() {},
  mounted() {
    this.fetchList();
    this.onLoadProductTree();
  },
  methods: {
    //加载机构信息
    async onSearchSellerCorporation() {
      let options = [];
      const resp = await fetchCorporationList("cycleOrder");
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaTreeData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaTreeData;
    },
    // 获取活动详情
    onLoadActivityDetail(id) {
      this.openModal = true;
      fetchExamineRideDetail(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.activityDetail = resp.data.data;
          if (
            this.activityDetail.goodsVoList &&
            this.activityDetail.goodsVoList.length > 2
          ) {
            this.lessGoodsVoList = [];
            this.lessGoodsVoList.push(
              this.activityDetail.goodsVoList[0],
              this.activityDetail.goodsVoList[1]
            );
          }
          this.bikeStations = this.activityDetail.bikeStations[0];
        }
      });
    },
    // 加载产品树
    onLoadProductTree() {
      fetchProductsAndSkuTree().then(resp => {
        if (resp.data.code === "SUCCESS") {
          const productList = [];
          resp.data.data.forEach(item => {
            if (item.skuList && item.skuList.length > 0) {
              productList.push(item);
            }
          });
          this.productList = productList;
          if (this.productList.length > 0) {
            this.productList.forEach(item => {
              item.id = item.productId;
              item.name = `${item.productName} ${item.productCode}`;
              if (item.skuList && item.skuList.length > 0) {
                item.skuList.forEach(items => {
                  items.id = items.skuId;
                  let skuName = "";
                  if (items.keyPropsMap) {
                    for (const [key, value] of Object.entries(
                      items.keyPropsMap
                    )) {
                      if (key) {
                        skuName += `${value} `;
                      }
                    }
                  }
                  items.name = `${items.skuCode} ${skuName}`;
                });
              }
            });
          }
        }
      });
    },
    // 获取列表信息
    fetchList() {
      const params = {
        ...this.form,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        areaCode: "",
        skuId: this.skuId ? this.skuId : ""
      };
      if (this.form.consigneeProvinceCode) {
        params.areaCode = this.form.consigneeProvinceCode;
      }
      if (this.form.consigneeCityCode) {
        params.areaCode = this.form.consigneeCityCode;
      }
      if (this.form.consigneeCountyCode) {
        params.areaCode = this.form.consigneeCountyCode;
      }
      fetchTrialLeaseList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              if (item.orderStatus) {
                item.orderStatus = "已完成";
              } else {
                item.orderStatus = "骑行中";
              }
              if (!item.productSerialNo) {
                item.productSerialNo = "--";
              }
              if (item.deliveryTime) {
                item.deliveryTime = tsFormat(item.deliveryTime);
              } else {
                item.deliveryTime = "--";
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    onFileStatusChange(info) {
      if (info.file.status === "done") {
        if (info.file.response.code === "SUCCESS") {
          this.$message.success("导入成功");
          this.onSearch();
        } else {
          this.impErrData = info.file.response.errorMsg;
          this.errModalVisible = true;

          this.$message.error("导入失败");
        }
      } else if (info.file.status === "error") {
        this.$message.error("导入失败");
      }
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.pagination.current = 1;
      this.fetchList();
    },
    //重置
    onReset() {
      this.skuId = "";
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 查看详情
    onDetail(id) {
      this.$router.push({
        name: "TrialLeaseDetailPxz",
        params: {
          id: id
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 100px;
}

.row {
  display: flex;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
}
.search__btn button {
  margin-right: 8px;
}
.action button {
  margin-right: 8px;
}
.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/deep/.ant-space-item {
  white-space: nowrap;
}
/deep/.ant-table-thead > tr > th {
  white-space: nowrap;
}
/deep/.ant-modal {
  width: 720px !important;
}
.advanced-search-forms/deep/ .ant-row {
  display: flex;
  justify-content: space-between;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10 {
  flex: 1;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10:first-child {
  margin-right: 30px;
}
.advanced-search-forms/deep/
  .ant-row
  .ant-col
  .ant-form-item
  .ant-form-item-label {
  min-width: 81px;
}
/deep/.ant-modal {
  width: 81% !important;
}
/deep/.ant-modal-body {
  overflow-y: auto;
  max-height: 61vh;
}
.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}
.car__img {
  width: 30%;
  overflow: hidden;
}
.car__img img {
  width: 100%;
  max-height: 90px;
  object-fit: cover;
}
.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}

.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.label {
  color: rgba(0, 0, 0, 0.85);
}
.label__content {
  margin: 24px 0;
}
.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}
.openPhoto span {
  margin: 0 4px 20px;
}
.card__title {
  color: rgba(0, 0, 0, 0.85);
  margin: 4px 0;
  font-size: 16px;
}
.common-search_form-item {
  width: 25%;
  display: inline-flex;
  margin-bottom: 24px;
}
</style>
