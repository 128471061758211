<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item><a @click="onBack">租赁订单</a></a-breadcrumb-item>
        <a-breadcrumb-item>查看详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">用户信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              用户名称：
            </div>
            <div class="card-item-content">
              {{ info.consumerName ? info.consumerName : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="card-item">
            <div class="card-item-title-content">
              手机号：
            </div>
            <div class="card-item-content">
              {{ info.mobile ? info.mobile : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单号：
            </div>
            <div class="card-item-content">
              {{ info.orderNo ? info.orderNo : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单状态：
            </div>
            <div class="card-item-content">
              {{ info.orderStatus ? "已完成" : "骑行中" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              下单时间：
            </div>
            <div class="card-item-content">
              {{ info.createTime ? dtFormat(info.createTime) : "" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">套餐信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              套餐期数：
            </div>
            <div class="card-item-content">
              {{ info.orderPeriod ? info.orderPeriod : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="card-item">
            <div class="card-item-title-content">
              是否免押金：
            </div>
            <div class="card-item-content">
              {{ info.depositSuccessFlag ? "通过" : "未通过" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              押金：
            </div>
            <div class="card-item-content">
              {{ info.deposit ? info.deposit : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              租赁开始时间：
            </div>
            <div class="card-item-content">
              {{ info.transDate ? dtFormat(info.transDate) : "" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              租赁结束时间：
            </div>
            <div class="card-item-content">
              {{ info.transEndDate ? dtFormat(info.transEndDate) : "" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container" style="padding-bottom: 25px">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">车辆骑行信息</div>
          </div>
        </a-col>
      </a-row>
      <div
        class="tip"
        style="margin-bottom: 10px;font-size: 10px;margin-left: 4px;"
      >
        当用户在活动时间范围内进行了更换车辆，则会有多条商品骑行数据
      </div>
      <a-table
        :data-source="tblData"
        :columns="columns"
        :pagination="false"
      ></a-table>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">活动信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              渠道类型：
            </div>
            <div class="card-item-content">
              {{ info.distributorType ? info.distributorType : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称：
            </div>
            <div class="card-item-content">
              {{ info.corporationName ? info.corporationName : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              举办地点：
            </div>
            <div class="card-item-content">
              {{
                info.hostAreaName +
                  (info.hostDetailAddress ? info.hostDetailAddress : "")
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              所属活动：
            </div>
            <div class="card-item-content">
              <span class="word--blue" @click="openModal = true">查看</span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal
      v-model="openModal"
      title="租赁活动"
      @cancel="openModal = false"
      class="modal"
    >
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动标题">
              {{
                activityDetail.activityTitle
                  ? activityDetail.activityTitle
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="渠道类型">
              {{
                activityDetail.distributorTypeValue
                  ? activityDetail.distributorTypeValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              {{
                activityDetail.corporationName
                  ? activityDetail.corporationName
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动时间">
              {{
                activityDetail.startTime && activityDetail.endTime
                  ? dtFormat(activityDetail.startTime) +
                    "~" +
                    dtFormat(activityDetail.endTime)
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动状态">
              {{
                activityDetail.activityStatusValue
                  ? activityDetail.activityStatusValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="举办地点">
              {{
                activityDetail.hostAreaName +
                  (activityDetail.hostDetailAddress
                    ? activityDetail.hostDetailAddress
                    : "")
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <div class="label">
            租赁车辆（{{
              activityDetail.goodsVoList
                ? activityDetail.goodsVoList.length
                : 0
            }}辆）:
          </div>
          <div class="label__content">
            <div
              class="goods"
              v-if="
                !isOpenRide &&
                  activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
            >
              <div
                v-for="(item, index) in lessGoodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="goods"
              v-if="
                isOpenRide ||
                  (activityDetail.goodsVoList &&
                    activityDetail.goodsVoList.length <= 2)
              "
            >
              <div
                v-for="(item, index) in activityDetail.goodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
              class="openPhoto"
              @click="isOpenRide = !isOpenRide"
            >
              <span>{{ !isOpenRide ? "展开" : "收起" }}</span>
              <span v-if="!isOpenRide">
                <a-icon type="down" />
              </span>
              <span v-else>
                <a-icon type="up" />
              </span>
            </div>
          </div>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import { formatList, toChinesNum } from "@/components/utils";
import Vue from "vue";
import { Modal } from "ant-design-vue";
import {
  fetchExamineRideDetail,
  fetchLeaseOrder
} from "@/services/ActivityService";

Vue.prototype.confirm = Modal.confirm;

const columns = [
  {
    title: "商品编号",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "20%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn"
  },
  {
    title: "开始时间",
    dataIndex: "startTime"
  },
  {
    title: "结束时间",
    dataIndex: "endTime"
  },
  {
    title: "总里程",
    dataIndex: "mileage"
  },
  {
    title: "总时长",
    dataIndex: "duration"
  }
];
export default {
  data() {
    return {
      tsFormat,
      dtFormat,
      formatList,
      openModal: false,
      imageBaseUrl,
      info: {},
      isOpen: false,
      isOpenRide: false,
      lessGoodsVoList: [],
      activityDetail: {},
      bikeStations: "",
      toChinesNum,
      tblData: [],
      columns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false
    };
  },
  mounted() {
    this.onLoadDetail();
  },
  methods: {
    //返回上一级
    onBack() {
      this.$router.go(-1);
    },
    // 获取活动详情
    onLoadActivityDetail(id) {
      fetchExamineRideDetail(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.activityDetail = resp.data.data;
          console.log(this.activityDetail);
          if (
            this.activityDetail.goodsVoList &&
            this.activityDetail.goodsVoList.length > 2
          ) {
            this.lessGoodsVoList = [];
            this.lessGoodsVoList.push(
              this.activityDetail.goodsVoList[0],
              this.activityDetail.goodsVoList[1]
            );
          }
          this.bikeStations = this.activityDetail.bikeStations[0];
        }
      });
    },
    //获取详情信息
    onLoadDetail() {
      fetchLeaseOrder(this.$route.params.id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.info.cycleRecordList.forEach(item => {
            item.startTime = item.startTime ? tsFormat(item.startTime) : "--";
            item.endTime = item.endTime ? tsFormat(item.endTime) : "--";
            item.mileage = item.mileage ? item.mileage + "km" : "--";
          });
          this.tblData = this.info.cycleRecordList;
          this.onLoadActivityDetail(this.info.activityId);
          if (this.info) {
            if (this.info.distributorType === "warehouse") {
              this.info.distributorType = "货栈";
            }
            if (this.info.distributorType === "operationCenter") {
              this.info.distributorType = "运营中心";
            }
            if (this.info.distributorType === "operationHeadquarters") {
              this.info.distributorType = "运营总部";
            }
            if (this.info.distributorType === "serviceCenter") {
              this.info.distributorType = "服务中心";
            }
            if (this.info.distributorType === "endConsumerMarket") {
              this.info.distributorType = "渠道终端";
            }
            if (this.info.distributorType === "factory") {
              this.info.distributorType = "制造原厂";
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}

/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}

.info-container /deep/ .ant-form-item {
  margin: 0;
}

/deep/.ant-input-number-handler-wrap {
  display: none;
}

.finish img {
  margin-bottom: 20px;
}

.finish span {
  margin-left: 18px;
}

/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}

/deep/ .ant-form-item-control {
  width: 90%;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0;
}
.card-item {
  display: flex;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}
.card-item-title-content {
  margin-right: 10px;
  min-width: 70px;
}
/deep/ .rejectForm .ant-form-item-label {
  min-width: 80px !important;
}
/deep/ .ant-table-footer {
  padding: 16px 0;
}
.word--blue {
  cursor: pointer;
  color: rgb(73, 110, 242);
}
/deep/.ant-modal {
  width: 81% !important;
}
/deep/.ant-modal-body {
  overflow-y: auto;
  max-height: 61vh;
}
.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}
.car__img {
  width: 30%;
  height: 90px;
  overflow: hidden;
}
.car__img img {
  width: 100%;
  object-fit: cover;
}
.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}
.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.label {
  color: rgba(0, 0, 0, 0.85);
}
.label__content {
  margin: 24px 0;
}
.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}
.openPhoto span {
  margin: 0 4px 20px;
}
.card__title {
  color: rgba(0, 0, 0, 0.85);
  margin: 4px 0;
  font-size: 16px;
}
.hint {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin-bottom: 8px;
}
</style>
