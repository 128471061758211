<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>用户数据</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch" @reset="onReset">
        <template>
          <a-form-item label="地区" class="common-search_form-item">
            <a-cascader
              v-model="areaCode"
              change-on-select
              :options="areaTreeData"
              :field-names="areaTreeNames"
              placeholder="请选择区域"
              :allow-clear="true"
              @change="areaCodeChange"
            />
          </a-form-item>
        </template>
        <template>
          <a-form-item label="机构名称" class="common-search_form-item">
            <a-select
              show-search
              :filter-option="filterInstitutionOption"
              placeholder="请选择机构"
              v-model="corporationId"
            >
              <a-select-option
                :value="item.corporationId"
                v-for="item in corporationList"
                :key="item.corporationId"
              >
                {{ item.corporationName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>
      </common-search>
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">
              详情
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>
<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { loadArea } from "@/services/DealerService";
import { getWebConsumerList } from "@/services/ActivityService";
import { organizationInfoListByAreaCode } from "@/services/ValueBag";

const columns = [
  {
    title: "用户名称",
    dataIndex: "customerName"
  },
  {
    title: "手机号",
    dataIndex: "mobile"
  },
  {
    title: "地区",
    dataIndex: "areaName"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "clientOrderData",
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "用户名称",
          key: "keyword",
          component: "j-input"
        }
      ],
      areaTreeData: [], // 行政区数据
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      form: {},
      corporationId: "",
      areaCode: [],
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      columns,
      tableData: [],
      corporationList: [] // 机构下拉列表
    };
  },

  mounted() {
    this.onLoadArea();
    this.getCorporationList();
    this.getTableList();
  },
  methods: {
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    // 获取表格数据
    async getTableList() {
      const params = {
        keyword: this.form.keyword ? this.form.keyword : "",
        corporationId: this.corporationId ? this.corporationId : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.areaCode) {
        if (this.areaCode.length === 0) {
          params.areaCode = "";
        }
        if (this.areaCode.length >= 1) {
          params.areaCode = this.areaCode[0];
        }
        if (this.areaCode.length >= 2) {
          params.areaCode = this.areaCode[1];
        }
        if (this.areaCode.length >= 3) {
          params.areaCode = this.areaCode[2];
        }
      } else {
        params.areaCode = "";
      }
      const { data } = await getWebConsumerList(params);
      this.tableData = data.data.records;
      this.pagination.total = data.data.total;
      this.pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getTableList();
    },
    // 搜索框重置
    onReset() {
      this.areaCode = [];
      this.corporationId = "";
    },
    // 地区数据更改
    areaCodeChange(value) {
      this.corporationId = "";
      this.getCorporationList(value[value.length - 1]);
    },
    //获取机构下拉列表
    getCorporationList(areaCode = "") {
      organizationInfoListByAreaCode({ areaCode }).then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    // 根据机构输入项进行筛选
    filterInstitutionOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onDetail(record) {
      this.$router.push({
        name: "webConsumerDetail",
        query: { id: record.customerId }
      });
    }
  }
};
</script>
<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
.common-search_form-item {
  width: 25%;
  display: inline-flex;
  margin-bottom: 24px;
}
</style>
