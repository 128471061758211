<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>零售订单</a-breadcrumb-item>
        <a-breadcrumb-item>订单详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!--订单信息-->
    <section class="card__container">
      <div class="container__title">订单信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编号">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="销售总额">
              <div>{{ info.totalPrice }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="销售时间">
              <div>{{ tsFormat(info.createTime) }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        class="commodity__table"
        :columns="orderColumns"
        :data-source="info.retailOrderGoodsVOList"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="fileForm" slot-scope="record">
          <img
            class="product__img"
            :src="imageBaseUrl + record.fileForm.fileUrl"
            alt="商品图片"
          />
        </template>
        <a-space slot="action" slot-scope="record">
          <a @click="toActivity(record)" v-if="record.activityId">查看</a>
          <div v-else>--</div>
        </a-space>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">商家信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="服务机构">
              <div>{{ info.corporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="机构类型">
              <div>{{ info.distributorTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="经营地区">
              <div>{{ info.areaName || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="container__title">消费者信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="用户名称">
              <div>{{ info.customerName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <div>{{ info.mobile }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <div>{{ info.idCardNo || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <!--活动详情弹窗-->
    <a-modal
      v-model="openModal"
      :title="activityDetail.activityTypeValue || ''"
      @cancel="openModal = false"
      class="modal"
    >
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动标题">
              {{
                activityDetail.activityTitle
                  ? activityDetail.activityTitle
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="渠道类型">
              {{
                activityDetail.distributorTypeValue
                  ? activityDetail.distributorTypeValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              {{
                activityDetail.corporationName
                  ? activityDetail.corporationName
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动时间">
              {{
                activityDetail.startTime && activityDetail.endTime
                  ? dtFormat(activityDetail.startTime) +
                    "~" +
                    dtFormat(activityDetail.endTime)
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动状态">
              活动
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="举办地点">
              {{ activityDetail.hostAreaName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="activityDetail.activityTypeValue === '试骑活动'">
          <a-col :span="12">
            <a-form-item label="还车位置">
              {{
                `${
                  bikeStations && bikeStations.latitude
                    ? "纬度:" + bikeStations.latitude
                    : "--"
                },${
                  bikeStations && bikeStations.longitude
                    ? "经度:" + bikeStations.longitude
                    : "--"
                }`
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="还车范围">
              附近{{
                bikeStations && bikeStations.radius
                  ? bikeStations.radius
                  : "--"
              }}米
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="activityDetail.activityTypeValue === '试骑活动'">
          <a-col :span="12">
            <a-form-item label="工作人员数">
              {{
                activityDetail.workerCnt
                  ? activityDetail.workerCnt + "人"
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动参与人数">
              {{
                activityDetail.joiningCount
                  ? activityDetail.joiningCount + "人"
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="activityDetail.activityTypeValue === '试骑活动'">
          <div class="label">
            活动现场照片（{{ activityDetail.length }}张）:
          </div>
          <div class="label__content">
            <div v-if="!isOpen" class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in activityDetail.activityPictures"
                :key="index"
                style="width: 33%"
              >
                <div v-if="index <= 2" class="content__img" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in activityDetail.activityPictures"
                :key="index"
                style="width: 32%;margin-right: 1%"
              >
                <div class="content__img" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    alt="现场照片"
                  />
                </div>
              </div>
            </div>
            <span
              v-if="
                activityDetail.activityPictures &&
                  activityDetail.activityPictures.length === 0
              "
            >
              暂无照片
            </span>
          </div>
          <div
            v-if="
              activityDetail.activityPictures &&
                activityDetail.activityPictures.length > 3
            "
            class="openPhoto"
            @click="isOpen = !isOpen"
          >
            <span>{{ !isOpen ? "展开" : "收起" }}</span>
            <span v-if="!isOpen">
              <a-icon type="down" />
            </span>
            <span v-else>
              <a-icon type="up" />
            </span>
          </div>
        </a-row>
        <a-row>
          <div class="label">试骑车辆（{{ activityDetail.goodsVoList ? activityDetail.goodsVoList.length : 0 }}辆）:</div>
          <div class="label__content">
            <div
              class="goods"
              v-if="
                !isOpenRide &&
                  activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
            >
              <div
                v-for="(item, index) in lessGoodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="goods"
              v-if="
                isOpenRide ||
                  (activityDetail.goodsVoList &&
                    activityDetail.goodsVoList.length <= 2)
              "
            >
              <div
                v-for="(item, index) in activityDetail.goodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
              class="openPhoto"
              @click="isOpenRide = !isOpenRide"
            >
              <span>{{ !isOpenRide ? "展开" : "收起" }}</span>
              <span v-if="!isOpenRide">
                <a-icon type="down" />
              </span>
              <span v-else>
                <a-icon type="up" />
              </span>
            </div>
          </div>
        </a-row>
        <a-row v-if="activityDetail.activityTypeValue === '试骑活动'">
          <div class="label">
            相关物料:
          </div>
          <a-col
            :span="12"
            v-for="item in activityDetail.supplyVoList"
            :key="item.supplyId"
          >
            <div class="card">
              <div class="card__title">
                {{ item.supplyName }}
              </div>
              <div class="card__detail">
                <span style="margin-right: 30px">
                  数量：{{ item.supplyNum ? item.supplyNum : "--" }}
                </span>
                <span>
                  价值：{{ item.supplyCost ? item.supplyCost : "--" }}
                </span>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout>
</template>
<script>
import { fetchExamineRideDetail } from "@/services/ActivityService";
import { getRetailOrderDetail } from "@/services/OrderService";
import { imageBaseUrl } from "@/services/HttpService";
import { tsFormat, dtFormat } from "@/components/DateUtils";
const orderColumns = [
  {
    title: "商品图片",
    key: "fileForm",
    width: "20%",
    scopedSlots: { customRender: "fileForm" }
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "20%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    width: "20%"
  },
  {
    title: "所属活动",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "retailOrderDetail",
  data() {
    return {
      imageBaseUrl,
      id: this.$route.query.id || "",
      orderColumns,
      info: {}, // 订单信息
      openModal: false, // 活动信息弹窗
      activityDetail: {}, // 活动信息
      bikeStations: "",
      isOpen: false,
      isOpenRide: false,
      lessGoodsVoList: []
    };
  },
  mounted() {
    // 获取订单详情
    this.getOrderDetail();
  },
  methods: {
    tsFormat,
    dtFormat,
    async getOrderDetail() {
      if (this.id) {
        const { data } = await getRetailOrderDetail(this.id);
        this.info = data.data;
      }
    },
    // 查看活动详情
    toActivity(record) {
      this.openModal = true;
      this.onLoadActivityDetail(record.activityId);
    },
    //  获取活动详情
    onLoadActivityDetail(id) {
      fetchExamineRideDetail(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.activityDetail = resp.data.data;
          if (
            this.activityDetail.goodsVoList &&
            this.activityDetail.goodsVoList.length > 2
          ) {
            this.lessGoodsVoList = [];
            this.lessGoodsVoList.push(
              this.activityDetail.goodsVoList[0],
              this.activityDetail.goodsVoList[1]
            );
          }
          this.bikeStations = this.activityDetail.bikeStations[0];
        }
      });
    }
  }
};
</script>
<style scoped>
.product__img {
  /*width: 100px;*/
  height: 100px;
}
.highlight {
  color: #2827ff;
  cursor: pointer;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}

/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}

.info-container /deep/ .ant-form-item {
  margin: 0;
}

/deep/.ant-input-number-handler-wrap {
  display: none;
}

.finish img {
  margin-bottom: 20px;
}

.finish span {
  margin-left: 18px;
}

/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}

/deep/ .ant-form-item-control {
  width: 90%;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/deep/ .rejectForm .ant-form-item-label {
  min-width: 80px !important;
}

/deep/.ant-modal {
  width: 81% !important;
}
/deep/.ant-modal-body {
  overflow-y: auto;
  height: 61vh;
}
.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}
.car__img {
  width: 30%;
  //height: 90px;
  max-height: 200px;
  overflow: hidden;
}
.car__img img {
  width: 100%;
  object-fit: cover;
}
.car__info {
  //height: 90px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}
.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.label {
  color: rgba(0, 0, 0, 0.85);
}
.label__content {
  margin: 24px 0;
}
.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}
.openPhoto span {
  margin: 0 4px 20px;
}
.card__title {
  color: rgba(0, 0, 0, 0.85);
  margin: 4px 0;
  font-size: 16px;
}
</style>
