<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>用户数据</a-breadcrumb-item>
        <a-breadcrumb-item>数据详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!--用户信息-->
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="用户名">
              <div>{{ info.customerName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <div>{{ info.mobile }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户来源">
              <div>{{ info.source }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <div>{{ info.idCardNo || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="居住地址">
              <div>{{ info.address || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系人姓名">
              <div>{{ info.contactName || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人关系">
              <div>{{ info.contactRelationship || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系人电话">
              <div>{{ info.contactPhone || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <!--试骑订单-->
    <section class="card__container">
      <div class="container__title">试骑订单</div>
      <div>
        <a-table
          class="commodity__table"
          :columns="cycleOrderColumns"
          :data-source="cycleOrderList"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record, 'ride')">链接</a>
          </a-space>

          <template slot="orderStatus" slot-scope="record">
            <div>{{ transStatus(record.orderStatus) }}</div>
          </template>
        </a-table>
      </div>
    </section>
    <!--租赁订单-->
    <section class="card__container">
      <div class="container__title">拍小租租赁订单</div>
      <div style="margin-bottom: 20px">
        <a-table
          class="commodity__table"
          :columns="rentOrderColumns"
          :data-source="rentOrderList"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record, 'lease', 'TrialLeaseDetailPxz')">
              链接
            </a>
          </a-space>
          <template slot="orderStatus" slot-scope="record">
            <div>{{ transStatus(record.orderStatus) }}</div>
          </template>
        </a-table>
      </div>

      <div class="container__title">电行世界租赁订单</div>
      <div>
        <a-table
          class="commodity__table"
          :columns="rentOrderColumns"
          :data-source="leaseOrderList"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record, 'lease', 'TrialLeaseDetailDxsj')">
              链接
            </a>
          </a-space>
          <template slot="orderStatus" slot-scope="record">
            <div>{{ dxsjTransStatus(record.orderStatus) }}</div>
          </template>
        </a-table>
      </div>
    </section>
    <!--零售订单-->
    <section class="card__container">
      <div class="container__title">零售订单</div>
      <div>
        <a-table
          class="commodity__table"
          :columns="salesOrderColumns"
          :data-source="retailOrderList"
          :pagination="false"
          :rowKey="(record, index) => index"
        >
          <template slot="orderStatus" slot-scope="record">
            <div>{{ transStatus(record.orderStatus) }}</div>
          </template>
        </a-table>
      </div>
    </section>
  </a-layout>
</template>
<script>
import { getWebConsumerDetail } from "@/services/ActivityService";
import { tsFormat } from "@/components/DateUtils";
const cycleOrderColumns = [
  {
    title: "车辆序列号",
    dataIndex: "goodsSn",
    width: "20%"
  },
  {
    title: "时间",
    dataIndex: "createTime",
    width: "20%"
  },
  {
    title: "服务机构",
    dataIndex: "corporationName",
    width: "20%"
  },
  {
    title: "状态",
    key: "orderStatus",
    scopedSlots: { customRender: "orderStatus" },
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const rentOrderColumns = [
  {
    title: "车辆序列号",
    dataIndex: "goodsSn",
    width: "20%"
  },
  {
    title: "金额",
    dataIndex: "price",
    width: "20%"
  },
  {
    title: "时间",
    dataIndex: "createTime",
    width: "20%"
  },
  {
    title: "服务机构",
    dataIndex: "corporationName",
    width: "20%"
  },
  {
    title: "状态",
    key: "orderStatus",
    scopedSlots: { customRender: "orderStatus" },
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const salesOrderColumns = [
  {
    title: "车辆序列号",
    dataIndex: "goodsSn",
    width: "20%"
  },
  {
    title: "金额",
    dataIndex: "price",
    width: "20%"
  },
  {
    title: "时间",
    dataIndex: "createTime",
    width: "20%"
  },
  {
    title: "服务机构",
    dataIndex: "corporationName",
    width: "20%"
  },
  {
    title: "状态",
    key: "orderStatus",
    scopedSlots: { customRender: "orderStatus" },
    width: "20%"
  }
];
export default {
  name: "webConsumerDetail",
  data() {
    return {
      info: {},
      cycleOrderList: [], //试骑订单表格数据
      rentOrderList: [], // 拍小租租赁订单表格数据
      leaseOrderList: [], // 电行世界租赁订单表格数据
      retailOrderList: [], // 零售订单表格数据
      cycleOrderColumns,
      rentOrderColumns,
      salesOrderColumns,
      clientTypeOptions: [
        { text: "试骑客户", value: "test_riding" },
        { text: "租赁客户", value: "rent" },
        { text: "零售客户", value: "car_sales" },
        { text: "租赁客户", value: "lease" }
        // { text: "直播活动", value: "live_telecast" }
      ]
    };
  },
  mounted() {
    this.getUserDetail();
  },
  methods: {
    // 获取用户详情
    async getUserDetail() {
      const { data } = await getWebConsumerDetail(this.$route.query.id);
      this.info = {
        customerName: data.data.customerName,
        mobile: data.data.mobile,
        corporationName: data.data.corporationName,
        idCardNo: data.data.idCardNo,
        address: data.data.address,
        contactName: data.data.contactName,
        contactRelationship: data.data.contactRelationship,
        contactPhone: data.data.contactPhone,
        source: this.clientTypeOptions.find(
          item => item.value === data.data.source
        ).text
      };
      this.cycleOrderList = data.data.cycleOrderList;
      this.rentOrderList = data.data.rentOrderList;
      this.leaseOrderList = data.data.leaseOrderList;
      this.retailOrderList = data.data.retailOrderList;
      this.cycleOrderList.forEach(item => {
        if (item.createTime) {
          item.createTime = tsFormat(item.createTime);
        } else {
          item.createTime = "--";
        }
      });
      this.rentOrderList.forEach(item => {
        if (item.createTime) {
          item.createTime = tsFormat(item.createTime);
        } else {
          item.createTime = "--";
        }
      });
      this.leaseOrderList.forEach(item => {
        if (item.createTime) {
          item.createTime = tsFormat(item.createTime);
        } else {
          item.createTime = "--";
        }
      });
      this.retailOrderList.forEach(item => {
        if (item.createTime) {
          item.createTime = tsFormat(item.createTime);
        } else {
          item.createTime = "--";
        }
      });
    },
    // 链接
    onDetail(record, type, name) {
      // type: ride(试骑订单), lease(租赁订单), retail(零售订单)
      // 试骑订单
      if (type === "ride") {
        this.$router.push({
          name: "TrialRideDetail",
          params: {
            id: record.orderSn
          }
        });
      } else if (type === "lease") {
        // 租赁订单
        this.$router.push({
          name: name,
          params: {
            id: record.orderSn
          }
        });
      }
    },
    // 判断订单状态
    transStatus(val) {
      if (val === 1) {
        return "已完成";
      } else if (val === 0) {
        return "骑行中";
      } else {
        return "";
      }
    },
    dxsjTransStatus(val) {
      switch (val) {
        case "to_payment":
          return "待支付";
        case "to_collected":
          return "待取车";
        case "to_sign":
          return "待签约";
        case "leasing":
          return "租用中";
        case "canceled":
          return "已取消";
        case "returned":
          return "已退租";
        case "renew_lease":
          return "续租中";
        case "complete":
          return "已完成";
        case "purchasing":
          return "分期购买中";
        case "purchase_complete":
          return "购车完成";
        default:
          break;
      }
    }
  }
};
</script>
<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}
.commodity__table {
  margin-top: 20px;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
</style>
