<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item>零售订单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <!-- 搜索框 -->
      <common-search :fields="fields" @search="onSearch"></common-search>

      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="saleDate" slot-scope="text, record">
            <div>{{ dtFormat(record.saleDate) }}</div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { loadArea } from "@/services/DealerService";
import { organizationInfoListByAreaCode } from "@/services/ValueBag";
import { getRetailOrder } from "@/services/OrderService";
import { dtFormat } from "@/components/DateUtils";

const columns = [
  {
    title: "订单编号",
    dataIndex: "orderSn"
  },
  {
    title: "用户名称",
    dataIndex: "customerName"
  },
  {
    title: "地区",
    dataIndex: "areaName"
  },
  {
    title: "服务机构",
    dataIndex: "corporationName"
  },
  {
    title: "金额",
    dataIndex: "totalPrice"
  },
  {
    title: "销售时间",
    dataIndex: "saleDate",
    scopedSlots: { customRender: "saleDate" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "retailOrder",
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "用户名称",
          key: "customerName",
          component: "j-input"
        },
        {
          label: "服务机构",
          key: "corporationId",
          component: "j-select-search",
          options: [],
          getOptions: () => {
            return this.onSearchSellerCorporation();
          }
        },
        {
          label: "订单时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "地区",
          key: "consigneeProvinceCode,consigneeCityCode,consigneeCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      areaTreeData: [], // 行政区数据
      form: {},
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      columns,
      tblData: [],
      corporationList: [] // 机构下拉列表
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    dtFormat,
    //加载机构信息
    async onSearchSellerCorporation() {
      let options = [];
      const resp = await organizationInfoListByAreaCode({ areaCode: "" });
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.corporationId,
            label: item.corporationName
          });
        });
      }
      return options;
    },
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaTreeData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaTreeData;
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    // 获取表格数据
    async fetchList() {
      const params = {
        customerName: this.form.customerName ? this.form.customerName : "",
        corporationId: this.form.corporationId ? this.form.corporationId : "",
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        areaCode: ""
      };
      if (this.form.consigneeProvinceCode) {
        params.areaCode = this.form.consigneeProvinceCode;
      }
      if (this.form.consigneeCityCode) {
        params.areaCode = this.form.consigneeCityCode;
      }
      if (this.form.consigneeCountyCode) {
        params.areaCode = this.form.consigneeCountyCode;
      }
      const { data } = await getRetailOrder(params);
      this.tblData = data.data.records;
      this.pagination.total = data.data.total;
      this.pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 查看详情
    onDetail(item) {
      // 跳转到活动详情页
      this.$router.push({
        name: "retailOrderDetail",
        query: { id: item.orderSn }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
</style>
