<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item><a @click="onBack">试骑订单</a></a-breadcrumb-item>
        <a-breadcrumb-item>查看详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">用户信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              用户名称：
            </div>
            <div class="card-item-content">
              {{ info.consumerName ? info.consumerName : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="card-item">
            <div class="card-item-title-content">
              手机号：
            </div>
            <div class="card-item-content">
              {{ info.mobile ? info.mobile : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单号：
            </div>
            <div class="card-item-content">
              {{ info.orderNo ? info.orderNo : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单状态：
            </div>
            <div class="card-item-content">
              {{ info.orderStatus ? "已完成" : "骑行中" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">车辆信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              商品名称：
            </div>
            <div class="card-item-content">
              {{ info.skuName ? info.skuName : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              商品编码：
            </div>
            <div class="card-item-content">
              {{ info.skuCode ? info.skuCode : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              序列号：
            </div>
            <div class="card-item-content">
              {{ info.goodsSn ? info.goodsSn : "--" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container" style="padding-bottom: 25px">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">骑行记录</div>
          </div>
        </a-col>
      </a-row>
      <a-table
        style="width: 70%;"
        :data-source="tblData"
        :columns="columns"
        :pagination="false"
      ></a-table>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">活动信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              渠道类型：
            </div>
            <div class="card-item-content">
              {{ info.distributorType ? info.distributorType : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称：
            </div>
            <div class="card-item-content">
              {{ info.corporationName ? info.corporationName : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              举办地点：
            </div>
            <div class="card-item-content">
              {{ info.hostDetailAddress ? info.hostAreaName+(info.hostDetailAddress?info.hostDetailAddress:'') : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              所属活动：
            </div>
            <div class="card-item-content">
              <span class="word--blue" @click="openModal = true">查看</span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal
      v-model="openModal"
      title="试骑活动"
      @cancel="openModal = false"
      class="modal"
    >
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动标题">
              {{
                activityDetail.activityTitle
                  ? activityDetail.activityTitle
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="渠道类型">
              {{
                activityDetail.distributorTypeValue
                  ? activityDetail.distributorTypeValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              {{
                activityDetail.corporationName
                  ? activityDetail.corporationName
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动时间">
              {{
                activityDetail.startTime && activityDetail.endTime
                  ? dtFormat(activityDetail.startTime) +
                    "~" +
                    dtFormat(activityDetail.endTime)
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="活动状态">
              {{
                activityDetail.activityStatusValue
                  ? activityDetail.activityStatusValue
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="举办地点">
              {{ activityDetail.hostAreaName+(activityDetail.hostDetailAddress?activityDetail.hostDetailAddress:'') }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="还车位置">
              {{
                `${
                  bikeStations && bikeStations.latitude
                    ? "纬度:" + bikeStations.latitude
                    : "--"
                },${
                  bikeStations && bikeStations.longitude
                    ? "经度:" + bikeStations.longitude
                    : "--"
                }`
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="还车范围">
              附近{{
                bikeStations && bikeStations.radius
                  ? bikeStations.radius
                  : "--"
              }}米
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="工作人员数">
              {{
                activityDetail.workerCnt
                  ? activityDetail.workerCnt + "人"
                  : "--"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="活动参与人数">
              {{
                activityDetail.joiningCount
                  ? activityDetail.joiningCount + "人"
                  : "--"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <div class="label">
            活动现场照片（（{{
              activityDetail.activityPictures
                ? activityDetail.activityPictures.length
                : 0
            }}张）:
          </div>
          <div class="label__content">
            <div v-if="!isOpen" class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in activityDetail.activityPictures"
                :key="index"
                style="width: 33%"
              >
                <div v-if="index <= 2" class="content__img" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in activityDetail.activityPictures"
                :key="index"
                style="width: 32%;margin-right: 1%"
              >
                <div class="content__img" v-viewer>
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    alt="现场照片"
                  />
                </div>
              </div>
            </div>
            <span
              v-if="
                activityDetail.activityPictures &&
                  activityDetail.activityPictures.length === 0
              "
            >
              暂无照片
            </span>
          </div>
          <div
            v-if="
              activityDetail.activityPictures &&
                activityDetail.activityPictures.length > 3
            "
            class="openPhoto"
            @click="isOpen = !isOpen"
          >
            <span>{{ !isOpen ? "展开" : "收起" }}</span>
            <span v-if="!isOpen">
              <a-icon type="down" />
            </span>
            <span v-else>
              <a-icon type="up" />
            </span>
          </div>
        </a-row>
        <a-row>
          <div class="label">
            试骑车辆（{{
              activityDetail.goodsVoList
                ? activityDetail.goodsVoList.length
                : 0
            }}辆）:
          </div>
          <div class="label__content">
            <div
              class="goods"
              v-if="
                !isOpenRide &&
                  activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
            >
              <div
                v-for="(item, index) in lessGoodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="goods"
              v-if="
                isOpenRide ||
                  (activityDetail.goodsVoList &&
                    activityDetail.goodsVoList.length <= 2)
              "
            >
              <div
                v-for="(item, index) in activityDetail.goodsVoList"
                :key="index"
                class="car__detail"
              >
                <div class="car__img" v-if="item.fileForm" v-viewer>
                  <img
                    data-source="imageBaseUrl + item.fileForm.fileUrl"
                    :src="imageBaseUrl + item.fileForm.fileUrl"
                    alt="车辆图片"
                  />
                </div>
                <div class="car__info">
                  <div class="info__title">
                    {{ item.skuName }}
                  </div>
                  <div>
                    <div class="info__word">序列号:{{ item.skuCode }}</div>
                    <div class="info__word">商品编号:{{ item.goodsSn }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                activityDetail.goodsVoList &&
                  activityDetail.goodsVoList.length > 2
              "
              class="openPhoto"
              @click="isOpenRide = !isOpenRide"
            >
              <span>{{ !isOpenRide ? "展开" : "收起" }}</span>
              <span v-if="!isOpenRide">
                <a-icon type="down" />
              </span>
              <span v-else>
                <a-icon type="up" />
              </span>
            </div>
          </div>
        </a-row>
        <a-row>
          <div class="label">
            相关物料:
          </div>
          <a-col
            :span="12"
            v-for="item in activityDetail.supplyVoList"
            :key="item.supplyId"
          >
            <div class="card">
              <div class="card__title">
                {{ item.supplyName }}
              </div>
              <div class="card__detail">
                <span style="margin-right: 30px">
                  数量：{{ item.supplyNum ? item.supplyNum : "--" }}
                </span>
                <span>
                  价值：{{ item.supplyCost ? item.supplyCost : "--" }}
                </span>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import { formatList, toChinesNum } from "@/components/utils";
import Vue from "vue";
import { Modal } from "ant-design-vue";
import {
  fetchCycleOrder,
  fetchExamineRideDetail
} from "@/services/ActivityService";

Vue.prototype.confirm = Modal.confirm;

const columns = [
  {
    title: "开始时间",
    dataIndex: "startTime"
  },
  {
    title: "结束时间",
    dataIndex: "endTime"
  },
  {
    title: "总里程",
    dataIndex: "mileage"
  },
  {
    title: "总时长",
    dataIndex: "duration"
  }
];
export default {
  data() {
    return {
      tsFormat,
      dtFormat,
      formatList,
      openModal: false,
      imageBaseUrl,
      info: {},
      isOpen: false,
      isOpenRide: false,
      lessGoodsVoList: [],
      activityDetail: {},
      bikeStations: "",
      toChinesNum,
      tblData: [],
      columns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false
    };
  },
  mounted() {
    this.onLoadDetail();
  },
  methods: {
    //返回上一级
    onBack() {
      this.$router.go(-1);
    },
    // 获取活动详情
    onLoadActivityDetail(id) {
      fetchExamineRideDetail(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.activityDetail = resp.data.data;
          if (
            this.activityDetail.goodsVoList &&
            this.activityDetail.goodsVoList.length > 2
          ) {
            this.lessGoodsVoList = [];
            this.lessGoodsVoList.push(
              this.activityDetail.goodsVoList[0],
              this.activityDetail.goodsVoList[1]
            );
          }
          this.bikeStations = this.activityDetail.bikeStations[0];
        }
      });
    },
    //获取详情信息
    onLoadDetail() {
      fetchCycleOrder(this.$route.params.id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          let params = {
            startTime: this.info.startTime
              ? tsFormat(this.info.startTime)
              : "--",
            endTime: this.info.endTime ? tsFormat(this.info.endTime) : "--",
            mileage:
              this.info.mileage !== null && this.info.mileage !== undefined
                ? this.info.mileage + "km"
                : "--",
            duration: this.info.duration ? this.info.duration : "--"
          };
          this.tblData.push(params);
          this.onLoadActivityDetail(this.info.activityId);
          if (this.info) {
            if (this.info.distributorType === "warehouse") {
              this.info.distributorType = "货栈";
            }
            if (this.info.distributorType === "operationCenter") {
              this.info.distributorType = "运营中心";
            }
            if (this.info.distributorType === "operationHeadquarters") {
              this.info.distributorType = "运营总部";
            }
            if (this.info.distributorType === "serviceCenter") {
              this.info.distributorType = "服务中心";
            }
            if (this.info.distributorType === "endConsumerMarket") {
              this.info.distributorType = "渠道终端";
            }
            if (this.info.distributorType === "factory") {
              this.info.distributorType = "制造原厂";
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}

/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}

.info-container /deep/ .ant-form-item {
  margin: 0;
}

/deep/.ant-input-number-handler-wrap {
  display: none;
}

.finish img {
  margin-bottom: 20px;
}

.finish span {
  margin-left: 18px;
}

/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}

/deep/ .ant-form-item-control {
  width: 90%;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0;
}
.card-item {
  display: flex;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}
.card-item-title-content {
  margin-right: 10px;
  min-width: 70px;
}
/deep/ .rejectForm .ant-form-item-label {
  min-width: 80px !important;
}
/deep/ .ant-table-footer {
  padding: 16px 0;
}
.word--blue {
  cursor: pointer;
  color: rgb(73, 110, 242);
}
/deep/.ant-modal {
  width: 81% !important;
}
/deep/.ant-modal-body {
  overflow-y: auto;
  height: 61vh;
}
.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}
.car__img {
  width: 30%;
  height: 90px;
  overflow: hidden;
}
.car__img img {
  width: 100%;
  object-fit: cover;
}
.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}
.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 20px;
}
.label {
  color: rgba(0, 0, 0, 0.85);
}
.label__content {
  margin: 24px 0;
}
.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}
.openPhoto span {
  margin: 0 4px 20px;
}
.card__title {
  color: rgba(0, 0, 0, 0.85);
  margin: 4px 0;
  font-size: 16px;
}
</style>
